import * as singleSpa from 'single-spa';
import { handleMicroAppUnmount, setupMicroApp } from './utils';

const vdlUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4010/static/js/main.js'
    : process.env.REACT_APP_VDL_JS_BUNDLE;
const VdlElementId = 'vdl';

const loadVdl = async () => {
  return setupMicroApp(vdlUrl, VdlElementId);
};

const application = {
  update: () => Promise.resolve(), //update function
  bootstrap: () => Promise.resolve(), //bootstrap function
  mount: () => Promise.resolve(loadVdl()), //mount function
  unmount: () => Promise.resolve(handleMicroAppUnmount()) //unmount function
};

export const registerVdlApp = () => {
  singleSpa.registerApplication('vdl', application, location => {
    return location.pathname.startsWith('/vdl');
  });
};
