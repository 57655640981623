import styled, { keyframes } from 'styled-components';

export const slideInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, 7%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`;

export const AlertMain = styled.div`
   {
    border-radius: 7px;
    display: flex;
    padding: 10px 10px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.6em;
    align-items: center;
    animation-name: ${slideInUp} !important;
    animation-duration: 0.6s !important;
    animation-delay: unset !important;
    svg {
      height: 19px;
      margin-right: 10px;
    }
    b {
      font-family: 'Sofia Pro Bold' !important;
    }
    &.success {
      color: #027a48;
      background-color: rgba(63, 218, 152, 0.1);
    }
    &.danger {
      color: #ed6e7e;
      background-color: rgba(218, 63, 63, 0.1);
    }
    &.warning {
      color: #bc5317;
      background: rgba(254, 161, 45, 0.1);
    }
    &.default {
      color: #667898;
      background: #eaecf1;
    }
  }
`;
