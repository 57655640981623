import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import * as singleSpa from 'single-spa';
import { MpharmaI18nProvider } from 'mpharma-i18n';
import { registerInventoryApp } from './micro-apps/inventory';
import { registerPosApp } from './micro-apps/pos';
import { registerOrdersApp } from './micro-apps/orders';
import { registerLabTestApp } from './micro-apps/labTest';
import { registerHealthServicesApp } from './micro-apps/healthServices';
import { register as registerServiceWorker } from './serviceWorker';
import db from './db/BloomDatabase';
import ENGLISH_TRANSLATIONS from './internationalisation/locales/default.en.json';
import FRENCH_TRANSLATIONS from './internationalisation/locales/default.fr.json';
import { registerFacilityInsightsApp } from './micro-apps/facilityInsights';
import { registerVdlApp } from './micro-apps/vdl';
import { APP_ENV, APP_RELEASE, APP_SENTRY_DSN, APP_VERSION } from './helpers/constants';
import { setCurrentVersionToLocal } from './helpers/utils';
import { registerAdminManagementApp } from './micro-apps/adminManagement';
import { registerMemberManagementApp } from './micro-apps/memberManagement';

const API_REGEXES = [
  /^https:\/\/api\.mpharma\.com/,
  /^https:\/\/bloom\.mpharma\.com/,
  /^https:\/\/api\.stage\.mpharma\.com/,
  /^https:\/\/bloom\.stage\.mpharma\.com/,
  /^https:\/\/api\.test\.mpharma\.com/,
  /^https:\/\/bloom\.test\.mpharma\.com/
];

setCurrentVersionToLocal(APP_VERSION);
Sentry.init({
  dsn: APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: API_REGEXES,
      traceFetch: true,
      traceXHR: true,
      shouldCreateSpanForRequest(url) {
        return API_REGEXES.some(regex => regex.test(url));
      }
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: API_REGEXES
    })
  ],
  environment: APP_ENV,
  enabled: APP_ENV !== 'test',
  release: APP_RELEASE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.5
});

(() => {
  db.createDB();
})();

ReactDOM.render(
  <MpharmaI18nProvider
    languages={[
      { key: 'en', name: 'English', translations: ENGLISH_TRANSLATIONS },
      { key: 'fr', name: 'French', translations: FRENCH_TRANSLATIONS }
    ]}
  >
    <App />
  </MpharmaI18nProvider>,
  document.getElementById('root')
);

registerInventoryApp();
registerPosApp();
registerFacilityInsightsApp();
registerOrdersApp();
registerLabTestApp();
registerHealthServicesApp();
registerAdminManagementApp();
registerMemberManagementApp();
registerVdlApp();
singleSpa.start();
registerServiceWorker();
