import React, { useEffect, useState } from 'react';
import Notification from './Notification';
import { Text } from 'mpharma-i18n';
import { CheckOutlined, InfoOutlined } from '@material-ui/icons';
import styled from 'styled-components';
import useBulkStockUploadNotifications from '../../hooks/useBulkStockUploadNotifications';

function StockTransferAlerts({ handleOpenView, notifications, openNotificationsDrawer }) {
  const [unseenAlerts, setUnseenAlerts] = useState([]);
  const [viewedAlerts, setViewedAlerts] = useState([]);
  const [showAggregatedResponse, setShowAggregatedResponse] = useState(false);

  const { bulkStockUploadNotifications } = useBulkStockUploadNotifications();
  const activeNotifications = notifications?.length ? notifications : bulkStockUploadNotifications;

  const dismissAlert = (isPrompt, id) => {
    if (!isPrompt) {
      const arr = [...viewedAlerts];
      arr.push(id);
      localStorage.setItem('bloom:viewedStockTransferNotifications', JSON.stringify(arr));
      setUnseenAlerts(activeNotifications?.filter(item => (arr?.length ? !arr?.includes(item.id) : item)));
    } else {
      localStorage.setItem('bloom:hideStockTransferPrompt', true);
    }
  };

  useEffect(() => {
    if (unseenAlerts?.length > 5 && !localStorage?.getItem('bloom:hideStockTransferPrompt')) {
      setUnseenAlerts([]);
      setShowAggregatedResponse(true);
    }
  }, [unseenAlerts]);

  useEffect(() => {
    const alertsFromStorage = JSON.parse(localStorage?.getItem('bloom:viewedStockTransferNotifications') || `[]`);
    setViewedAlerts(alertsFromStorage);
    if (!localStorage?.getItem('bloom:hideStockTransferPrompt')) {
      setUnseenAlerts(
        activeNotifications?.filter(item => (alertsFromStorage?.length ? !alertsFromStorage?.includes(item.id) : item))
      );
    }
  }, [notifications, bulkStockUploadNotifications, activeNotifications]);

  return (
    <Container>
      {unseenAlerts?.map(notification => {
        const { process_status, transfer_request_id, id, destination_facility_name, file_upload } = notification;
        const isComplete = process_status === 'PROCESSING_COMPLETE';
        const isError = process_status === 'FAILED_VALIDATION';
        const isProcessing = process_status === 'PROCESSING_DATA';

        if (isProcessing) {
          return null;
        }
        return (
          <Notification
            className="stock_transfer_alert"
            key={notification?.id}
            link={() => {
              dismissAlert(false, notification?.id);
              handleOpenView(isError, isComplete, id, transfer_request_id);
            }}
            message={
              <>
                <b style={{ fontFamily: 'Sofia Pro Bold', marginBottom: 5, display: 'block' }}>
                  {isError ? (
                    <Text i18nKey="notifications.stockTransferUploadFailed">Stock transfer upload failed</Text>
                  ) : (
                    <Text i18nKey="notifications.stockTransferUploadSuccess">Stock transfer upload completed</Text>
                  )}
                </b>
                {isError && <>{file_upload?.errors[0]?.error}</>}
                {isComplete && (
                  <Text
                    i18nKey="notifications.stockTransferSuccessText"
                    variables={{ products: file_upload?.num_of_records, facilityName: destination_facility_name }}
                  >
                    Successfully initiated the transfer of {file_upload?.num_of_records} product(s) to
                    {destination_facility_name}
                  </Text>
                )}
              </>
            }
            action={<Text i18nKey="view">View</Text>}
            textStyle={{ width: 'unset', lineHeight: '1.5em' }}
            icon={
              isError ? <InfoOutlined style={{ color: '#C80000' }} /> : <CheckOutlined style={{ color: '#26855D' }} />
            }
            critical={isError}
            success={isComplete}
            handleClose={() => dismissAlert(false, notification?.id)}
          />
        );
      })}
      {showAggregatedResponse && (
        <Notification
          link={openNotificationsDrawer}
          message={
            <Text i18nKey="notifications.stockTransferAggregationText">
              Some of your stock transfer requests have new updates. Open notifications to see them.
            </Text>
          }
          action={<Text i18nKey="notifications.viewNotifications">View Notifications</Text>}
          textStyle={{ width: 'unset', lineHeight: '1.7em' }}
          icon={<InfoOutlined style={{ color: '#587CC8' }} />}
          info
          handleClose={() => {
            setShowAggregatedResponse(false);
            dismissAlert(true, null);
          }}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  .stock_transfer_alert {
    display: none;
    &:first-of-type {
      display: flex;
    }
  }
`;

export default StockTransferAlerts;
