import axios from 'axios';
import axiosRetry from 'axios-retry';
import { logOut } from '../../common/state/auth/reducers';
import { getCookie, requestInterceptor, responseInterceptor } from '../../helpers/utils';

const idToken = getCookie('idToken');

axiosRetry(axios, { retries: 3, retryCondition: axiosRetry.isSafeRequestError });
axios.defaults.withCredentials = false;
axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseInterceptor);

const { fetch: origFetch } = window;
window.fetch = async (...args) => {
  if (!idToken) {
    logOut();
  }
  const response = await origFetch(...args);
  if (response && response.status === 401) {
    logOut();
  }
  return response;
};

export async function get(url) {
  const res = await axios.get(url);
  if (res.statusText !== 'OK') throw res.data;
  return res.data;
}

export async function post(url, succeed, fail, data) {
  try {
    const res = await axios.post(url, data);
    if (res.statusText !== 'OK') return fail(res.data);
    succeed(res.data);
  } catch (err) {
    fail(err);
  }
}

export async function patch(url, succeed, fail, data) {
  try {
    const res = await axios.patch(url, data);
    if (res.statusText !== 'OK') return fail(res.data);
    succeed(res.data);
  } catch (err) {
    fail(err);
  }
}

export async function put(url, succeed, fail, data) {
  try {
    const res = await axios.put(url, data);
    if (res.statusText !== 'OK') return fail(res.data);
    succeed(res.data);
  } catch (err) {
    fail(err);
  }
}
