import { useState } from 'react';
import { BroadcastChannel as BroadcastChannelBackup } from 'broadcast-channel';

function useBroadcastChannel(channelName) {
  const [channelData, setChannelData] = useState(null);

  const GenericBroadcastChannel = typeof BroadcastChannel === 'undefined' ? BroadcastChannelBackup : BroadcastChannel;
  const broadcastChannel = new GenericBroadcastChannel(channelName);
  broadcastChannel.onmessage = e => {
    const { data } = e.data;
    if (data) {
      setChannelData(data);
    }
    broadcastChannel.close();
  };

  return [channelData, setChannelData];
}

export default useBroadcastChannel;
