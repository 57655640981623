import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { renderAppLinks } from '../../../helpers/utils';
import { API_ROOT, DRAWER_WIDTH, MY_ACTIVITIES } from '../../../helpers/constants';
import { ReactComponent as HuggingFaceEmoji } from '../../../images/HuggingFace.svg';
import { Text, useMpharmaI18n } from 'mpharma-i18n';
import { useMixpanel } from '../Mixpanel/Provider';
import { useLocalStorage } from 'react-use';
import { BloomToolsParent } from './styles';
import { ReactComponent as BellSimple } from '../../../images/BellSimple.svg';
import MessageCard from '../MessageCard/MessageCard';
import { get } from '../../../admin/api';

function BloomTools({ classes, history, contractType, setActiveTab }) {
  const [user] = useLocalStorage('bloom:user');
  const [loading, setIsLoading] = useState(true);
  const [MyFacility, setMyFacility] = useState(null);
  const mixpanel = useMixpanel();
  const { translateKey } = useMpharmaI18n();
  function mapAppLinkToUI({ HomeIcon, handleClick, tooltip }, id) {
    return (
      <div
        className={`${classes.moduleCard} animated`}
        onClick={() => {
          mixpanel.track('Click Bloom App', { app_name: translateKey(tooltip, { lng: 'en' }) });
          handleClick();
        }}
        key={id}
        data-testid={`home-page-${tooltip}`}
      >
        {HomeIcon && <HomeIcon height={50} width={50} />}
        <Text i18nKey={tooltip} />
      </div>
    );
  }

  //TO-DO: We only need the facility name, get an endpoint that returns only name.
  useEffect(() => {
    const getFacilityDetails = async () => {
      const URL = `${API_ROOT}/stores-ms/general/facilities/${user?.facility_id}/`;
      try {
        const data = await get(URL);
        setMyFacility(data.content[0]);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    let mounted = true;

    if (mounted) {
      getFacilityDetails();
    }
    return () => (mounted = false);
  }, [user?.facility_id]);

  return (
    <BloomToolsParent className={classes.container} data-testid="home-page-wrapper">
      {user?.groups?.includes('Owner') && !loading && (
        <MessageCard type="warning">
          <BellSimple />
          <span>
            Complete setup for {MyFacility?.name} to get the most out of Bloom.{' '}
            <b style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setActiveTab(MY_ACTIVITIES)}>
              Go to My activities
            </b>
          </span>
        </MessageCard>
      )}

      <header className={classes.header}>
        <HuggingFaceEmoji className="animated_slideInUp" data-testid="hugging-face-emoji" />
        <h1 className={`${classes.salutation} animated_slideInUp`}>
          <Text i18nKey="home.welcomeMessage">Welcome to Bloom</Text>, {user && user.first_name}
        </h1>
      </header>
      <h4 className={`${classes.categoryName} animated_slideInUp`}>
        <Text i18nKey="home.pharmacyManagement">Pharmacy Management</Text>
      </h4>
      <div className={classes.innerContainer}>
        {renderAppLinks(user, history, contractType)
          .filter(link => link.category === 'Pharmacy_Management')
          .map(mapAppLinkToUI)}
      </div>
      <h4 className={classes.categoryName}>Mutti</h4>
      <div className={classes.innerContainer}>
        {renderAppLinks(user, history, contractType)
          .filter(link => link.category === 'My_Mutti')
          .map(mapAppLinkToUI)}
      </div>
    </BloomToolsParent>
  );
}

const styles = () => ({
  container: {
    minHeight: '100vh',
    backgroundColor: '#fafafa',
    padding: '0px 16px',

    '@media (min-width: 768px)': {
      padding: `0px ${DRAWER_WIDTH + 76}px 117px`
    }
  },
  header: {
    marginTop: '48px',
    marginBottom: '48px',

    '@media (min-width: 768px)': {
      marginTop: 0
    }
  },
  salutation: {
    fontSize: 30,
    color: '#262626',
    marginTop: '0.7rem',
    lineHeight: '1rem',
    fontFamily: 'Sofia Pro Bold',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      justifyContent: 'flex-start'
    }
  },
  welcome: {
    fontWeight: 500,
    fontSize: 20,
    marginTop: '0.5rem'
  },
  innerContainer: {
    display: 'flex',
    marginBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'center',

    '@media (min-width: 768px)': {
      justifyContent: 'flex-start'
    }
  },
  categoryName: {
    fontSize: 16,
    marginBottom: 14,
    color: '#000000E3',
    fontWeight: 'normal'
  },
  moduleCard: {
    height: 192,
    width: 199,
    backgroundColor: 'white',
    boxShadow: '0 0 4px -1px rgba(0,0,0,0.2)',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 17,
    marginBottom: 17,
    flexDirection: 'column',
    cursor: 'pointer',
    fontWeight: 500,
    padding: '2rem',
    textAlign: 'center',
    '&:hover': {
      borderRadius: '6px',
      backgroundColor: '#F9F9F9',
      boxShadow: '0 12px 33px 0 rgba(0, 0, 0, 0.1)'
    },
    '&:nth-child(even)': {
      marginLeft: 0
    },
    '&:first-child': {
      marginLeft: 0
    }
  }
});

BloomTools.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  contractType: PropTypes.string.isRequired
};

export default withStyles(styles)(BloomTools);
