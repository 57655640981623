import * as singleSpa from 'single-spa';
import { handleMicroAppUnmount, setupMicroApp } from './utils';

const memberManagementUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4008/static/js/main.js'
    : process.env.REACT_APP_MEMBER_MANAGEMENT_JS_BUNDLE;
const memberManagementElementId = 'members';

const loadMemberManagement = async () => {
  return setupMicroApp(memberManagementUrl, memberManagementElementId);
};

const application = {
  update: () => Promise.resolve(), //update function
  bootstrap: () => Promise.resolve(), //bootstrap function
  mount: () => Promise.resolve(loadMemberManagement()), //mount function
  unmount: () => Promise.resolve(handleMicroAppUnmount()) //unmount function
};

export const registerMemberManagementApp = () => {
  singleSpa.registerApplication('members', application, location => {
    return location.pathname.startsWith('/members');
  });
};
