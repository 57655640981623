import React from 'react';
import MyActivitiesOnboardingAssociate from './OnboardingAssociate';
import MyActivitiesFacilityOwner from './FacilityOwner';
import { useLocalStorage } from 'react-use';

const MyActivities = () => {
  const [user] = useLocalStorage('bloom:user');

  if (user?.groups?.includes('Owner')) {
    return <MyActivitiesFacilityOwner />;
  } else if (user?.is_staff) {
    return <MyActivitiesOnboardingAssociate />;
  } else {
    return null;
  }
};

export default MyActivities;
