import React, { useState } from 'react';
import BloomTools from './BloomTools';
import { HomeContainer, HomeTabs } from './styles';
import MyActivities from './MyActivities';
import { Text } from 'mpharma-i18n';
import { useLocalStorage } from 'react-use';
import { BLOOM_TOOLS, MY_ACTIVITIES } from '../../../helpers/constants';

const Home = props => {
  const [activeTab, setActiveTab] = useState(BLOOM_TOOLS);
  const [user] = useLocalStorage('bloom:user');

  return (
    <HomeContainer data-testid="new-home">
      {(user?.groups.includes('Owner') || user?.is_staff) && (
        <HomeTabs>
          <button className={`${activeTab === BLOOM_TOOLS && 'active'}`} onClick={() => setActiveTab(BLOOM_TOOLS)}>
            <Text i18nKey="home.bloomTools">Bloom tools</Text>
          </button>
          <button
            className={`${activeTab === MY_ACTIVITIES && 'active'} __indicator`}
            onClick={() => setActiveTab(MY_ACTIVITIES)}
          >
            <Text i18nKey="home.myActivities">My activities</Text>
          </button>
        </HomeTabs>
      )}
      {activeTab === BLOOM_TOOLS && <BloomTools {...props} setActiveTab={setActiveTab} />}
      {activeTab === MY_ACTIVITIES && <MyActivities />}
    </HomeContainer>
  );
};

export default Home;
