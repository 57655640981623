import styled from 'styled-components';
import { primaryYellow } from '../../styles';

export const AccordionContainer = styled.div`
   {
    ._facilityAccordion {
      margin-top: 20px;
      animation-delay: 0.1s;
      background: unset;
      box-shadow: unset;
      &:hover {
        ._summary {
          box-shadow: 0px 2px 5px 0px #00000010;
        }
      }
      &::before {
        background-color: unset;
      }
      &.Mui-expanded {
        svg.expand_svg {
          transform: rotate(-180deg);
        }
        ._summary {
          min-height: unset;
          height: 45px;
          box-shadow: 0px 2px 5px 0px #00000010;
        }
      }
      svg {
        transition: all 0.4s ease;
      }
      ._summary {
        transition: all 0.4s ease;
        min-height: unset;
        height: 45px;
        background: #fff;
        border-radius: 5px;
        p + p {
          margin-top: 0px !important;
        }
        ._facilityName {
          font-size: 15px;
          font-family: 'Sofia Pro Bold';
          position: relative;
          padding-left: 30px;
          font-size: ;
        }
        ._facilityName::before {
          content: '';
          width: 13px;
          height: 13px;
          left: 3px;
          border-radius: 100%;
          position: absolute;
          top: 5px;
          background: ${primaryYellow};
        }
        ._tasksCompleted {
          margin-left: auto;
          font-size: 13px;
          padding-right: 20px;
        }
      }
      ._details {
        background: unset;
        margin: 20px 0px;
        padding: 0px 45px;
      }
    }
  }
`;

export const FacilityActivityContainer = styled.div`
   {
    display: flex;
    border-bottom: 1px solid #e8ebec;
    padding: 10px 0px;
    &.pending {
      p {
        color: #234670;
      }
    }
    svg.svg {
      margin: 5px 10px 5px 0px;
    }
    p {
      font-size: 14px;
      color: #6b7b8a;
    }
    p.link {
      cursor: pointer;
    }
    p.link:hover {
      text-decoration: underline;
    }
    button {
      margin-left: auto;
      color: #234670;
      font-family: 'Sofia Pro Bold';
      border: 0;
      text-decoration: underline;
      cursor: pointer;
      background: unset;
    }
    button:hover {
      color: ${primaryYellow};
    }
  }
`;

export const ErrorText = styled.div`
   {
    margin: 20px 0;
    font-size: 14px;
    button {
      color: #234670;
      font-family: 'Sofia Pro Bold';
      border: 0;
      text-decoration: underline;
      cursor: pointer;
      background: unset;
    }
  }
`;
