import React, { useEffect, useState } from 'react';
import { ActivityBox, GuideCardContainer, OnwerActivitiesContainer, TitleTextContainer } from './styles';
import { Text } from 'mpharma-i18n';
import { useLocalStorage } from 'react-use';
import { FullScreenLoader, MyActivitiesContainer } from '../styles';
import { FacilityActivity } from '../OnboardingAssociate';
import { Check } from '@material-ui/icons';
import { API_ROOT, BLOOM_GUIDE_LINKS, IMPORTANT_DATES_REFERENCE } from '../../../../../helpers/constants';
import { CircularProgress } from '@mui/material';
import { get, patch } from '../../../../../admin/api';
import Alert from 'mpharma-components-library/lib/Alert';

const MyActivitiesFacilityOwner = () => {
  const [loading, setIsLoading] = useState(true);
  const [MyFacility, setMyFacility] = useState(null);
  const [error, setError] = useState(false);
  const [alertState, setAlertState] = useState(null);

  const [user] = useLocalStorage('bloom:user');

  useEffect(() => {
    const getFacilityDetails = async () => {
      const URL = `${API_ROOT}/stores-ms/general/facilities/${user?.facility_id}/`;
      try {
        const data = await get(URL);
        setMyFacility(data.content[0]);
        setIsLoading(false);
        setError(false);
      } catch (err) {
        setError(true);
        setIsLoading(false);
      }
    };
    let mounted = true;

    if (mounted) {
      getFacilityDetails();
    }
    return () => (mounted = false);
  }, [user?.facility_id]);

  const handleClose = () => {
    setAlertState(null);
  };
  return (
    <MyActivitiesContainer style={{ paddingBottom: 100 }}>
      <h2>
        <Text
          i18nKey="home.heySalutation"
          variables={{
            firstName: `${user && user.first_name}`,
            punctuation: ','
          }}
        >
          Hey, {user && user.first_name}
        </Text>
      </h2>
      <h3>
        <Text i18nKey="home.hereIsWhatsHappening">Here's what's happening in your facility's account</Text>
      </h3>
      {loading ? (
        <CircularProgress size={30} sx={{ color: '#dddddd' }} />
      ) : (
        <OnwerActivitiesContainer>
          {!error && <YourProgress user={user} setError={setError} setAlertState={setAlertState} />}
          {MyFacility?.quality_rx_details?.contract_dates && <ImportantDates MyFacility={MyFacility} />}
          <YourFacilityNumbers />
          <YourGuide />
        </OnwerActivitiesContainer>
      )}
      {alertState === 'success' && (
        <Alert
          open={true}
          position="topRight"
          success={true}
          message={<Text i18nKey="notifications.markAsDone.success">Successfully marked item as done.</Text>}
          handleClose={handleClose}
        />
      )}
      {alertState === 'error' && (
        <Alert
          open={true}
          position="topRight"
          error={true}
          message={<Text i18nKey="notifications.markAsDone.error">Failed to mark item as done. Please try again</Text>}
          handleClose={handleClose}
        />
      )}
      {alertState === 'loading' && (
        <FullScreenLoader>
          <CircularProgress />
        </FullScreenLoader>
      )}
    </MyActivitiesContainer>
  );
};

const YourProgress = ({ user, setError, setAlertState }) => {
  const [Activities, setActivities] = useState(null);

  useEffect(() => {
    const getPendingActivities = async () => {
      const URL = `${API_ROOT}/insights-bff/facilities/setup-tracker/${user?.facility_id}/`;
      try {
        const data = await get(URL);
        setActivities(data);
      } catch (err) {
        setError(true);
      }
    };
    let mounted = true;

    if (mounted) {
      getPendingActivities();
    }
    return () => (mounted = false);
  }, [user?.facility_id, setError]);

  const markAsDone = type => {
    setAlertState('loading');
    const BASE_URL = `${API_ROOT}/insights-bff/facilities/setup-tracker/${user?.facility_id}/`;

    patch(
      `${BASE_URL}`,
      () => {
        setAlertState('success');
        setActivities({ ...Activities, [type]: true });
      },
      () => {
        setAlertState('error');
      },
      { [type]: true }
    );
  };
  if (Activities) {
    return (
      <ActivityBox>
        <h3>Your progress</h3>
        <div className="card __big">
          <div className="__facility_status">
            <FacilityActivity
              status={Activities?.setup_facility ? 'complete' : 'pending'}
              title="Set up your facility"
            />
            <FacilityActivity
              status={Activities?.download_bloom_mobile_and_login ? 'complete' : 'pending'}
              title="Download Bloom mobile and login"
              onClick={
                !Activities?.download_bloom_mobile_and_login && {
                  label: (
                    <>
                      <Check /> Mark as done
                    </>
                  ),
                  action: () => markAsDone('download_bloom_mobile_and_login')
                }
              }
            />
            <FacilityActivity
              status={Activities?.download_anydesk ? 'complete' : 'pending'}
              title={
                <>
                  Download Anydesk{' '}
                  <a href="https://anydesk.com/" style={{ color: 'inherit' }} rel="noreferrer" target="_blank">
                    Click here
                  </a>
                </>
              }
              onClick={
                !Activities?.download_anydesk && {
                  label: (
                    <>
                      <Check /> Mark as done
                    </>
                  ),
                  action: () => markAsDone('download_anydesk')
                }
              }
            />
            <FacilityActivity
              status={Activities?.has_mutti_member ? 'complete' : 'pending'}
              title="Register your first Mutti member"
            />
            <FacilityActivity
              status={Activities?.made_first_bloom_sale ? 'complete' : 'pending'}
              title="Make your first Bloom sale"
            />
          </div>
        </div>
      </ActivityBox>
    );
  } else {
    return null;
  }
};

const ImportantDates = ({ MyFacility }) => {
  const [ImportantDates, setImportantDates] = useState([]);
  const dates = MyFacility?.quality_rx_details?.contract_dates;
  useEffect(() => {
    const dates_array = [];
    for (let i in dates) {
      dates_array.push({
        title: IMPORTANT_DATES_REFERENCE[i],
        date: new Date(dates[i]).toDateString()
      });
    }
    setImportantDates(dates_array);
  }, [dates]);

  return (
    <ActivityBox style={{ animationDelay: '0.3s' }}>
      <h3>Important dates</h3>
      <div className="card __big">
        {ImportantDates.map(date => (
          <TitleTextCard key={date?.title} title={date?.title} subtitle={date?.date} />
        ))}
      </div>
    </ActivityBox>
  );
};

const YourFacilityNumbers = () => {
  return (
    <ActivityBox style={{ width: '100%', flex: '0 1 100%', animationDelay: '0.3s' }}>
      <h3>Your facility's numbers</h3>
      <div
        className="card"
        style={{ minHeight: 96, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
      >
        <TitleTextCard title="GHS 250,000.67" subtitle="Stock on hand value" useHeading />
        <TitleTextCard title="GHS 1,550,000.00" subtitle="Investment received" useHeading />
        <TitleTextCard title="36 months" subtitle="Total contract length" useHeading />
        <TitleTextCard title="GHS 0.00" subtitle="Returns since joining" useHeading />
        <TitleTextCard title="GHS 1,550,000.00" subtitle="Amount left in payback" useHeading />
      </div>
    </ActivityBox>
  );
};

const YourGuide = () => {
  return (
    <ActivityBox style={{ width: '100%', flex: '0 1 100%', animationDelay: '0.3s' }}>
      <h3>Your guide: Bloom and Mutti</h3>
      <div style={{ minHeight: 96, display: 'flex', flexWrap: 'wrap' }}>
        {BLOOM_GUIDE_LINKS.map(link => (
          <GuideCard guide={link} key={JSON.stringify(link?.title)} />
        ))}
      </div>
    </ActivityBox>
  );
};

//REUSABLES
const GuideCard = ({ guide }) => {
  const { title, link, icon } = guide;

  return (
    <GuideCardContainer href={link} target="_blank">
      {icon} <p>{title}</p>
    </GuideCardContainer>
  );
};
const TitleTextCard = ({ title, subtitle, useHeading }) => {
  return (
    <TitleTextContainer style={{ borderRight: useHeading ? '1px solid #eee' : 0, paddingRight: 30 }}>
      <span>{subtitle}</span>
      {useHeading ? <h4>{title}</h4> : <p>{title}</p>}
    </TitleTextContainer>
  );
};

export default MyActivitiesFacilityOwner;
