import styled from 'styled-components';
import { primaryOrange } from '../../styles';

export const OnwerActivitiesContainer = styled.div`
   {
    display: flex;
    margin-top: 35px;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
    max-width: 1300px;
    @media (max-width: 950px) {
      flex-direction: column;
    }
  }
`;

export const ActivityBox = styled.div`
   {
    flex: 0 1 calc(49.4% - 10px);
    margin-bottom: 35px;
    height: auto;
    h3 {
      font-size: 16px;
      color: #262626 !important;
      font-family: Sofia Pro Bold !important;
    }
    div.card {
      padding: 20px 25px;
      background: #ffffff;
      box-shadow: 0px 0px 3.61884px -0.904711px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      height: auto;
      &.__big {
        height: 330px;
      }
      .__facility_status {
        div {
          border-bottom: 0;
          p {
            font-size: 13px;
          }
          button {
            font-size: 10px;
            text-decoration: none;
            color: ${primaryOrange};
            font-family: Sofia Pro;
            margin-left: 20px;
            svg {
              width: unset;
              height: 16px;
              margin: 0;
              margin-bottom: -3px;
              padding: 0;
              padding-top: 3px;
            }
          }
        }
      }
    }
  }
`;

export const TitleTextContainer = styled.div`
   {
    margin-top: 5px;
    margin-bottom: 5px;
    @media (max-width: 950px) {
      border-right: unset !important;
    }
    span {
      font-size: 13px;
      color: #6b7b8a;
      display: block;
      margin-bottom: 8px;
    }
    p {
      color: #234670;
      font-size: 14px;
      margin-bottom: 10px;
    }
    h4 {
      font-size: 25px;
      font-weight: 200;
      margin-top: -4px;
      color: #262626;
    }
    &:last-of-type {
      border-right: unset !important;
    }
  }
`;

export const GuideCardContainer = styled.a`
   {
    display: block;
    text-decoration: none;
    width: 130px;
    height: 100px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 15px 15px;
    background: #ffffff;
    box-shadow: 0px 0px 3.61884px -0.904711px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transform: scale(1);
    transition: all 0.4s ease;
    &:hover {
      transform: scale(1.1);
      background: #fff8e8;
    }
    p {
      color: #262626;
      font-size: 13px;
    }
  }
`;
