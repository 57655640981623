import styled, { keyframes } from 'styled-components';
export const primaryYellow = '#fdc302';
export const primaryOrange = '#FF5100';

//ANIMATIONS
export const slideInLeft = keyframes`
from {
  opacity: 0;
  transform: translate3d(6%, 0, 0);
}

to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}
`;

export const slideInUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0, 7%, 0);
}

to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
`;
//ANIMATIONS

export const HomeContainer = styled.div`
   {
    width: auto;
    min-height: 100vh;
    padding-top: 65px;
    background: #fafafa;
  }
`;

export const BloomToolsParent = styled.div`
   {
    .animated {
      animation-name: ${slideInLeft};
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }
    .animated_slideInUp {
      animation-name: ${slideInUp};
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }

    h1,
    h3 {
      animation-delay: 0.1s;
      animation-name: ${slideInUp};
    }

    h4 {
      animation-delay: 0.2s;
      animation-name: ${slideInUp};
    }
    div {
      animation-delay: 0.5s;
    }
    div:nth-of-type(1) {
      animation-delay: 0.2s;
    }
    div:nth-of-type(2) {
      animation-delay: 0.3s;
    }
    div:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
`;
export const HomeTabs = styled.div`
   {
    width: auto;
    height: 50px;
    margin: 30px 130px;
    border-bottom: 1px solid #e4e7ec;
    button {
      padding: 0;
      height: 100%;
      border: 0;
      background: unset;
      margin-right: 30px;
      color: #6b7b8a;
      position: relative;
      cursor: pointer;
    }
    button:hover {
      color: ${primaryYellow};
    }
    button::after {
      transition: all 0.4s ease;
      width: 0px;
      content: '';
    }
    button.active {
      color: ${primaryYellow};
      font-family: 'Sofia Pro Bold';
    }
    button.active::after {
      transition: all 0.4s ease;
      content: '';
      width: 100%;
      position: absolute;
      bottom: -1px;
      height: 1px;
      left: 0;
      background: ${primaryYellow};
    }
    button.__indicator::before {
      content: '';
      width: 6px;
      right: -8px;
      height: 6px;
      border-radius: 100%;
      position: absolute;
      top: 15px;
      background: ${primaryYellow};
    }
    @media (max-width: 768px) {
      padding: 0px;
      margin: 30px 25px;
    }
  }
`;
