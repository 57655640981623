import React, { useEffect, useState } from 'react';
import { AccordionContainer, ErrorText, FacilityActivityContainer } from './styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { ExpandMore } from '@material-ui/icons';
import { ReactComponent as DoubleCheck } from '../../../../../images/DoubleCheck.svg';
import { ReactComponent as Pending } from '../../../../../images/Pending.svg';
import { Text } from 'mpharma-i18n';
import { FullScreenLoader, MyActivitiesContainer } from '../styles';
import { get, post } from '../../../../../admin/api';
import { useLocalStorage } from 'react-use';
import { API_ROOT, ONBOARDING_ACTIVITES_REFERENCE } from '../../../../../helpers/constants';
import { CircularProgress } from '@mui/material';
import Alert from 'mpharma-components-library/lib/Alert';
import { navigateToUrl } from 'single-spa';

const MyActivitiesOnboardingAssociate = () => {
  const [expanded, setExpanded] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Facilities, setFacilities] = useState(null);
  const [alertState, setAlertState] = useState(null);

  const [user] = useLocalStorage('bloom:user');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const getPendingActivities = async () => {
      const URL = `${API_ROOT}/insights-bff/facilities/onboarding-tracker/`;
      try {
        const data = await get(URL + '?user_id=' + user?.id);
        setFacilities(data.results);
        setIsLoading(false);
      } catch (err) {
        setError(true);
        setIsLoading(false);
      }
    };
    let mounted = true;

    if (mounted) {
      getPendingActivities();
    }
    return () => (mounted = false);
  }, [user?.id]);

  const handleClose = () => {
    setAlertState(null);
  };

  return (
    <MyActivitiesContainer style={{ maxWidth: '700px', paddingBottom: 100 }}>
      <h2>
        <Text i18nKey="home.seeWhatsNew">See what's new and what you need to do</Text>
      </h2>
      <h3>
        <Text i18nKey="home.facilitiesNotLiveYet">Facilities not live yet</Text>
      </h3>
      {loading && <CircularProgress size={30} sx={{ color: '#dddddd' }} />}
      {error && (
        <ErrorText>
          <Text i18nKey="error.message">Sorry something bad happened, Please refresh</Text>.
        </ErrorText>
      )}
      <AccordionContainer>
        {Facilities &&
          Facilities?.map(facility => (
            <FacilityAccordion
              setAlertState={setAlertState}
              key={facility.facility_id}
              facility={facility}
              expanded={expanded}
              handleChange={handleChange}
            />
          ))}
      </AccordionContainer>
      {alertState === 'success' && (
        <Alert
          open={true}
          position="topRight"
          success={true}
          message={<Text i18nKey="notifications.activities.success">Successfully alerted the relevant team.</Text>}
          handleClose={handleClose}
        />
      )}
      {alertState === 'error' && (
        <Alert
          open={true}
          position="topRight"
          error={true}
          message={<Text i18nKey="notifications.activities.error">Failed to alert the team. Please try again</Text>}
          handleClose={handleClose}
        />
      )}
      {alertState === 'loading' && (
        <FullScreenLoader>
          <CircularProgress />
        </FullScreenLoader>
      )}
    </MyActivitiesContainer>
  );
};

const FacilityAccordion = ({ setAlertState, facility, expanded, handleChange }) => {
  const AlertRelevantTeam = async (type, id, e) => {
    setAlertState('loading');
    const URL = `${API_ROOT}/insights-bff/facilities/notify-onboarding-team/`;
    post(
      URL + id + '/',
      () => setAlertState('success'),
      () => setAlertState('error'),
      {
        team: type
      }
    );
  };

  const getAlertFn = (title, id) => {
    if (title.includes('price')) {
      return {
        label: <Text i18nKey="home.alertPricingTeam">Alert pricing team</Text>,
        action: () => AlertRelevantTeam('pricing_list', id)
      };
    }
    if (title.includes('stock')) {
      return {
        label: <Text i18nKey="home.alertInventoryTeam">Alert inventory team</Text>,
        action: () => AlertRelevantTeam('stock_count', id)
      };
    }
    if (title.includes('delivery')) {
      return {
        label: <Text i18nKey="home.alertWarehouseTeam">Alert warehouse team</Text>,
        action: () => AlertRelevantTeam('delivery_notes', id)
      };
    }
  };

  const activitiesAsArray = facility => {
    const array = [];

    for (let i in facility) {
      if (typeof facility[i] === 'boolean') {
        array.push({
          id: i,
          title: ONBOARDING_ACTIVITES_REFERENCE[i]?.title,
          status: facility[i] ? 'complete' : 'pending'
        });
      }
    }
    facility['activities'] = array;
  };

  useEffect(() => {
    activitiesAsArray(facility);
  }, [facility]);

  const progress_ratio = facility.progress_ratio.split('/');

  return (
    <>
      <Accordion
        className="_facilityAccordion"
        expanded={expanded === facility.id}
        onChange={handleChange(facility.id)}
      >
        <AccordionSummary
          className="_summary"
          expandIcon={<ExpandMore className="expand_svg" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <p className="_facilityName">{facility?.facility_name}</p>
          <p className="_tasksCompleted">
            {progress_ratio[0]} <Text i18nKey="home.of">of</Text> {progress_ratio[1]}{' '}
            <Text i18nKey="home.tasksCompleted">tasks completed</Text>
          </p>
        </AccordionSummary>
        <AccordionDetails className="_details">
          {facility?.activities?.map(activity => {
            const { status, title, id } = activity;
            return (
              <FacilityActivity
                key={title}
                status={status}
                title={title}
                facility={facility}
                onClick={title?.includes('Upload') && getAlertFn(title, facility?.facility_id)}
                link={ONBOARDING_ACTIVITES_REFERENCE[id]?.link}
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export const FacilityActivity = ({ facility, title, status, onClick, link }) => {
  const facility_id = facility?.facility_id;
  const [loading, setIsLoading] = useState(false);

  const getFacilityDetails = async () => {
    setIsLoading(true);
    const URL = `${API_ROOT}/stores-ms/general/facilities/${facility_id}/`;
    try {
      const data = await get(URL);
      localStorage.setItem('newFacility', JSON.stringify(data.content[0]));
      localStorage?.setItem('facilityProgress', JSON.stringify(facility));
      setTimeout(() => {
        navigateToUrl(link);
      }, 1000);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <FacilityActivityContainer className={status}>
      {loading ? (
        <CircularProgress size={20} sx={{ color: '#fdc302' }} />
      ) : (
        <>
          {status === 'complete' ? <DoubleCheck className="svg" /> : <Pending style={{ margin: '0px 18px 4px 0px' }} />}
          {link ? (
            <p className="link" onClick={getFacilityDetails}>
              {title}
            </p>
          ) : (
            <p>{title}</p>
          )}
          {onClick && (
            <button name={`button_${facility_id}`} onClick={() => onClick?.action(facility_id)}>
              {onClick?.label}
            </button>
          )}
        </>
      )}
    </FacilityActivityContainer>
  );
};

export default MyActivitiesOnboardingAssociate;
