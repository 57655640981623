import { COUNTRIES_WITH_ENABLED_FACILITY_MANAGEMENT, PERMISSION_TO_CREATE_PROMOS } from '../helpers/constants';
export const accountSettingsLinks = [
  {
    title: 'home.accountSettings.profile',
    link: 'profile'
  },
  {
    title: 'home.accountSettings.account',
    link: 'settings'
  },
  {
    title: 'home.accountSettings.changePassword',
    link: 'change-password'
  },
  { title: 'home.accountSettings.permissions' }
];

export const settingMenuLinks = (history, isAdmin, user, openModal, setOpen) => ({
  'home.sidebar.adminSettings': {
    menus: [
      {
        title: 'home.sidebar.manageUsers.title',
        subTitle: 'home.sidebar.manageUsers.subtitle',
        handleClick: () => {
          setOpen({});
          return history.push('/inventory/settings/users');
        }
      },
      ...(isAdmin
        ? [
            {
              title: 'home.sidebar.manageFacilities.title',
              subTitle: 'home.sidebar.manageFacilities.subtitle',
              handleClick: () => {
                setOpen({});
                return history.push('/inventory/settings/facilities');
              }
            },
            {
              title: 'home.sidebar.managePrices.title',
              subTitle: 'home.sidebar.managePrices.subtitle',
              handleClick: () => openModal('openFormulary')
            }
          ]
        : []),
      ...(user?.user_scopes?.includes(PERMISSION_TO_CREATE_PROMOS)
        ? [
            {
              title: 'home.sidebar.promotionsAndDiscounts.title',
              subTitle: 'home.sidebar.promotionsAndDiscounts.subtitle',
              handleClick: () => {
                setOpen({});
                return history.push('/pos/promos-and-discounts');
              }
            }
          ]
        : []),
      ...(!isAdmin &&
      user?.groups?.includes('Admin') &&
      COUNTRIES_WITH_ENABLED_FACILITY_MANAGEMENT.includes(user?.location)
        ? [
            {
              title: 'home.sidebar.manageFacility.title',
              subTitle: 'home.sidebar.manageFacility.subtitle',
              handleClick: () => {
                setOpen({});
                return history.push(`/inventory/facilities/${user?.facility_id}/profile`);
              }
            }
          ]
        : []),
      {
        title: 'home.sidebar.changeLanguage.title',
        subTitle: 'home.sidebar.changeLanguage.subtitle',
        handleClick: () => openModal('openChangeLanguage')
      }
    ]
  }
});
