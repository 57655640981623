import styled from 'styled-components';
import { primaryOrange, slideInLeft, slideInUp } from '../styles';

export const MyActivitiesContainer = styled.div`
   {
    margin: 30px 130px;
    h2,
    h3,
    div {
      line-height: unset;
      animation-name: ${slideInUp};
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }

    div {
      animation-name: ${slideInLeft};
      animation-duration: 0.6s;
      animation-fill-mode: both;
    }
    h2 {
      font-family: 'Sofia Pro Bold';
      margin: 20px 0px 15px;
      font-size: 24px;
      color: #262626;
    }
    h3 {
      font-family: 'Sofia Pro' !important;
      font-weight: 300;
      font-size: 16px;
      color: #6b7b8a !important;
      animation-delay: 0.1s;
      margin-bottom: 20px;
    }
    @media (max-width: 768px) {
      padding: 0px;
      margin: 30px 25px;
    }
  }
`;

export const FullScreenLoader = styled.div`
   {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background: rgba(255, 255, 255, 0.7);
    width: 100%;
    span {
      color: ${primaryOrange};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
