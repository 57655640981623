import React from 'react';
import { AlertMain } from './styles';
import PropTypes from 'prop-types';

const MessageCard = props => {
  const { style, type, children } = props;
  return (
    <AlertMain data-testid={props?.testId || 'message-card-alert'} className={type || 'default'} style={style}>
      {children}
    </AlertMain>
  );
};

MessageCard.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired
};

export default MessageCard;
