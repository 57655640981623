import React, { useEffect, useState, useRef } from 'react';
import Notification from './Notification';
import { Text } from 'mpharma-i18n';
import useOfflineStatus from '../../hooks/useOfflineStatus';

const OfflineNotification = ({ facilityObject }) => {
  const { online } = useOfflineStatus();

  const DEFAULT_OFFLINE_LIMIT = 24;
  const DEFAULT_OFFLINE_NOTIFICATION_REPEAT = 1;

  const [showOfflineNotification, setShowOfflineNotification] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);

  const offlineConfig = facilityObject?.offline_config;

  const offlineRepeat = offlineConfig?.offline_notification_period || DEFAULT_OFFLINE_NOTIFICATION_REPEAT;
  const offlineLimit = offlineConfig?.offline_time_allowed || DEFAULT_OFFLINE_LIMIT;
  let notificationInterval = useRef(null);

  useEffect(() => {
    let _timeElapsed = 0;
    if (!online) {
      let notificationTimeout = offlineRepeat * 60 * 60 * 1000;
      notificationInterval.current = setInterval(() => {
        _timeElapsed = _timeElapsed + Number(offlineRepeat);
        setTimeElapsed(_timeElapsed);
        setShowOfflineNotification(true);

        if (online) {
          setShowOfflineNotification(false);
          if (notificationInterval.current) {
            return clearInterval(notificationInterval.current);
          }
        }
      }, notificationTimeout);
    } else {
      setShowOfflineNotification(false);
      return clearInterval(notificationInterval.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  return (
    <>
      {showOfflineNotification && (
        <Notification
          message={
            <Text
              i18nKey="home.offlineNotificationMessage"
              variables={{
                elapsedTime: timeElapsed,
                timeRemaining: offlineLimit - timeElapsed >= 0 ? offlineLimit - timeElapsed : 0
              }}
            >
              You have been offline for {Number(timeElapsed)} hour(s), you need to connect to the internet and sync data
              in {offlineLimit - timeElapsed >= 0 ? offlineLimit - timeElapsed : 0} hour(s) to keep working.
            </Text>
          }
          warning
          handleClose={() => setShowOfflineNotification(false)}
        />
      )}
    </>
  );
};

export default OfflineNotification;
